import { city, province, area } from '@/common/js/area'

export const SEX_NAME = ['女', '男', '其它']

export const CAR_AREA = ['京', '津', '沪', '渝', '桂', '蒙', '新', '宁', '藏', '冀', '豫', '云', '辽', '黑', '湘', '皖', '鲁', '苏', '浙', '赣', '鄂', '甘', '晋', '陕', '吉', '闽', '贵', '青', '川', '琼', '粤']

export const AREA_TREE = province.map(row => {
  let v = false
  let cityList = city.filter(r => r.p === row.p)
  if (cityList.length === 0) {
    v = true
    cityList = area.filter(rr => rr.p === row.p)
  }
  return {
    label: row.n,
    value: row.c,
    children: cityList.map(r => {
      return {
        label: r.n,
        value: r.c,
        children: v
          ? null
          : area.filter(rr => rr.p === r.p && rr.t === r.t).map(rr => {
            return {
              label: rr.n,
              value: r.c
            }
          })
      }
    })
  }
})

export const AREA_CITY_TREE = province.map(row => {
  let cityList = city.filter(r => r.p === row.p)
  if (cityList.length === 0) {
    cityList = area.filter(rr => rr.p === row.p)
  }
  if (['北京市', '上海市', '天津市', '重庆市'].includes(row.n)) {
    return {
      label: row.n,
      value: row.c,
      children: null
    }
  } else {
    const cityChildren = cityList.map(r => {
      return {
        label: r.n,
        value: r.c,
        children: null
      }
    })
    return {
      label: row.n,
      value: row.c,
      children: cityChildren.length > 0 ? cityChildren : null
    }
  }
})

export const RESIDE_STATUS = ['按揭', '自购', '租房', '其它']

export const EDUCATION = ['小学', '初中', '高中', '大专', '本科', '研究生', '硕士及以上']

export const COOP_STATUS = ['停用', '正常']

export const RELATIONSHIP = ['其他', '朋友', '同事', '同学', '亲戚', '父母']

export const FLOW_STATUS = ['需求待确认', '重复申请', '待预审', '预审拒绝', '待提交申请资料', '待授信', '授信拒绝', '待签约', '待提交请款', '待放款', '已放款', '放弃申请', '放弃签约']

export const CAR_OWNER = ['否', '是']

export const MARITAL_STATUS = ['未婚', '已婚', '离异', '丧偶']

export const LOAN_NUMBER = [12, 24, 36, 48, 60]

export const LOAN_USE = ['其它', '教育', '旅游', '装修', '电器', '婚庆', '3C数码']
