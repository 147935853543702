import { render, staticRenderFns } from "./hcd-edit-dialog.vue?vue&type=template&id=2c67373a&scoped=true&"
import script from "./hcd-edit-dialog.vue?vue&type=script&lang=js&"
export * from "./hcd-edit-dialog.vue?vue&type=script&lang=js&"
import style0 from "./hcd-edit-dialog.vue?vue&type=style&index=0&id=2c67373a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c67373a",
  null
  
)

export default component.exports