
import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import hcdComponent from '@/web/components/index.js'
import './assets/css/common.less'
import Element from 'element-ui'
// import element from '@/web/utils/element'
import axios from 'axios'
axios.defaults.baseURL = '/loan/api/v1'

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token')
  if (token) {
    config.headers.accessToken = token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(res => {
  if (res?.data?.code > 200 && res?.data?.message) {
    if (res?.data?.code === 401) {
      router.push('/login')
      // window.location.href = '#/login'
    } else {
      window.global.$message.error('' + res?.data?.message)
      return Promise.reject(new Error('' + res?.data?.message))
    }
  } else {
    return Promise.resolve(res)
  }
})

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Element, { size: 'small' })
Vue.use(hcdComponent)
Vue.mixin({
  methods: {
    getPermissionList () {
      const permission = localStorage.getItem('permission') || '[]'
      return JSON.parse(permission)
    },
    hasPermission (code) {
      const permission = this.getPermissionList()
      return permission.includes(code)
    }
  }
})

window.global = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
