import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerList } from './router.main'
import MainView from '@/web/components/hcd-frame.vue'
import LoginView from '@/web/views/login/index.vue'
import PreviewView from '@/web/views/preview.vue'

Vue.use(VueRouter)

const flattenTree = (data, prefix) => {
  const list = data.reduce((pre, item) => [...pre, item, ...flattenTree(item.children || [], item.path)], [])
  list.forEach(row => {
    row.aPath = prefix + (row.aPath || row.path)
  })
  return list
}

const flatRoutes = flattenTree(routerList, '')

const routers = flatRoutes.map(row => {
  return {
    id: row.id,
    path: row.aPath,
    component: row.component
  }
})

const routes = [
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/preview',
    component: PreviewView
  },
  {
    path: '/',
    name: 'home',
    component: MainView,
    children: routers
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/web/views/error.vue')
  }
]

// const routes = [
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.path !== '/login' && to.path !== '/preview' && !token) {
    router.push('/login')
  } else {
    next()
  }
})

export default router
