<template>
    <div class="preview">
        <iframe class="preview-content" :src="url"></iframe>
    </div>
</template>

<script>
export default {
  data () {
    return {
      url: null
    }
  },
  watch: {
    '$route.query.url': {
      handler (val) {
        const inputUrl = new URL(val)
        inputUrl.searchParams.append('preview', 'true')
        this.url = inputUrl.toString()
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.preview {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;
    box-sizing: border-box;
    .preview-content {
        width: 300px;
        border: 0px;
        // &::-webkit-scrollbar {
        //     width: 0;
        //     height: 0;
        // }
    }
}

</style>
