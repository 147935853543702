import hcdPage from './hcd-page.vue'
import hcdMoreButtom from './hcd-more-buttom.vue'
import hcdEditDialog from './hcd-edit-dialog.vue'
import hcdCarnoInput from './hcd-carno-input.vue'
import hcdAreaSelect from './hcd-area-select.vue'
import hcdAmountInput from './hcd-amount-input.vue'
import hcdTree from './hcd-tree.vue'
import hcdHalfDialog from './hcd-half-dialog'
export default {
  install (Vue) {
    Vue.component('hcd-page', hcdPage)
    Vue.component('hcdMoreButtom', hcdMoreButtom)
    Vue.component('hcdEditDialog', hcdEditDialog)
    Vue.component('hcdCarnoInput', hcdCarnoInput)
    Vue.component('hcdAreaSelect', hcdAreaSelect)
    Vue.component('hcdAmountInput', hcdAmountInput)
    Vue.component('hcdTree', hcdTree)
    Vue.component('hcdHalfDialog', hcdHalfDialog)
  }
}
