export default [
  {
    path: '/consultation',
    meta: {
      id: '',
      icon: '',
      name: 'consultation',
      title: '客户咨询',
      code: 'customer:advisory:page'
    },
    component: () => import('@/web/views/consultation.vue')
  },
  {
    path: '/apply',
    meta: {
      id: '',
      icon: '',
      name: 'apply',
      title: '客户申请',
      code: 'customer:apply:customer:page'
    },
    component: () => import('@/web/views/apply.vue')
  },
  {
    path: '/report',
    meta: {
      id: '',
      icon: '',
      name: 'report',
      title: '数据报表',
      code: 'data:page'
    },
    component: () => import('@/web/views/report.vue')
  },
  // {
  //   path: '/squeeze',
  //   meta: {
  //     id: '',
  //     icon: '',
  //     name: 'squeeze',
  //     title: '咨询落地页',
  //     code: 'channel:page:page'
  //   },
  //   component: () => import('@/web/views/squeeze.vue')
  // },
  {
    path: '/coop',
    meta: {
      id: '',
      icon: '',
      name: 'coop',
      title: '渠道管理',
      code: 'channel:provider:page'
    },
    component: () => import('@/web/views/coop.vue')
  },
  {
    path: '/rule',
    meta: {
      id: '',
      icon: '',
      name: 'rule',
      title: '分配规则',
      code: 'rule:page'
    },
    component: () => import('@/web/views/rule.vue')
  },
  {
    path: '/member',
    meta: {
      id: '',
      icon: '',
      name: 'member',
      title: '成员管理',
      code: 'user:page:list'
    },
    component: () => import('@/web/views/member.vue')
  },
  {
    path: '/privilege',
    meta: {
      id: '',
      icon: '',
      name: 'privilege',
      title: '权限管理',
      code: 'role:page'
    },
    component: () => import('@/web/views/privilege.vue')
  }
  // {
  //   path: '/menus',
  //   meta: {
  //     id: '',
  //     icon: '',
  //     name: 'privilege',
  //     title: '菜单管理',
  //     apis: [
  //     ]
  //   },
  //   component: () => import('@/web/views/menus.vue')
  // }
]
