var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hcd-half-screen-dialog"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modal),expression:"modal"}],class:{'hcd-dialog-mask': true, active: _vm.classActive},on:{"click":_vm.clickClose}}),_c('div',{class:{
    'hcd-half-full-screen-box': true,
    'active': _vm.classActive
  },style:({
    width:  _vm.width + 'px'
  })},[_c('div',{staticClass:"dialog-header"},[_c('span',{staticClass:"dialog-back",on:{"click":_vm.clickClose}},[_c('i',{staticClass:"el-icon-close"}),_vm._v(" 关闭 ")]),_c('span',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.iconClass)?_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.iconTip,"placement":"bottom"}},[_c('i',{class:_vm.iconClass})]):_vm._e(),_vm._t("head")],2),_c('div',{staticClass:"dialog-body",class:{
      hasfooter: _vm.$slots.footer ? true : false
    }},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"dialog-footer"},[_vm._t("footer")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }