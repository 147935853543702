<template>
    <div :class="{'hcd-tree':true,reSizeable:reSizeable}">
      <div class="title">{{title}}
        <span
          v-if="firstAdd && canAdd(null)"
          :class="{icon:true, 'el-icon-plus':true,icondisabled: disabled({root:true,data:treeData})}"
          @click="function(){if(!disabled({root:true,data:treeData}))inputToggle('add')}"
          :title="disabled({root:true,data:treeData})||addTitle()||'添加'"
        ></span>
        <span
          v-if="options.search"
          class="icon el-icon-search"
          @click="inputToggle('search')"
          title="搜索"
        ></span>
        <el-dropdown v-if="options.filter" :class="activeCommand && 'filtered-dropdown'" trigger="click" placement="bottom" @command="handleFilter">
          <span class="filter-icon component_iconfont icon-shaixuan" title="筛选"></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="option in filterOptions"
              :key="option.command"
              :command="option.command"
              :class="activeCommand === option.command && 'hcd-tree__active-item'"
            >{{option.label}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <slot name="header"></slot>
      <div
        class="opt"
        v-if="filterShow"
      >
        <el-input
          v-if="filterShow ==='search'"
          placeholder="请输入您要搜索的内容"
          v-model="filterText"
          size="mini"
        />
        <div
          class="out-add"
          v-if="filterShow === 'add'"
        >
          <el-input
            :placeholder="placeholder('add')"
            v-model="addText"
            size="mini"
            @blur="blur()"
            @focus="focus()"
            @keydown.native.enter="addR"
            :maxlength="maxlength({root:true})"
          />
          <span
            :class="{'el-icon-check':true, icon:true}"
            :title="addTitle(null,'check') || '添加节点'"
            @click="addR"
          ></span>
          <span
            class="el-icon-close icon"
            title="取消"
            @click="cancleR"
          ></span>
        </div>
      </div>

      <div
        class="tree"
        :style="{ height:  height?height:((_height - delHeight)+ 'px') }"
      >
        <el-tree
          :data="treeData"
          :filter-node-method="filterNode"
          :props="defaultProps"
          :node-key="props.nodeKey"
          :expand-on-click-node="true"
          :render-content="renderContent"
          v-bind="bind"
          v-on="on"
          @node-click="handlerNodeClick"
          ref="tree"
        >
        </el-tree>
      </div>
    </div>
  </template>

<script>

import { each } from '@/web/utils/tools'
export default {
  name: 'hcd-tree',
  data () {
    return {
      activeNode: null, // 点击更多时对应的节点
      // eslint-disable-next-line vue/no-reserved-keys
      _height: 0,
      filterText: '',
      addText: '',
      addcText: '',
      filterShow: false,
      hasOpt: false,
      treeData: [],
      activeCommand: ''
    }
  },
  props: {
    firstAdd: {
      type: Boolean,
      default: true
    },
    customAdd: { // 是否自定义添加操作
      type: Boolean,
      default: false
    },
    addTitle: {
      default () {
        return () => {}
      }
    },
    reSizeable: {
      default: false
    },
    rootAddSort: {
      default: 'down'
    },
    addSort: {
      default: 'up'
    },
    handlerAdd: {
      type: Function
    },
    placeholder: {
      default () {
        return (status, node) => {
          return {
            add: '请输入需要添加的内容',
            edit: '请输入需要修改的内容'
          }[status]
        }
      }
    },
    mostTitle: {
      default () {
        return () => '更多'
      }
    },
    maxlength: {
      default () {
        return () => 30
      }
    },
    disabled: {
      default () {
        return () => {}
      }
    },
    options: {
      default () {
        return {}
      }
    },
    bind: {},
    autoHeight: {
      default: 200
    },
    height: {},
    title: {},
    data: {
      default () {
        return []
      }
    },
    props: {},
    noParent: {
      default: 0
    },
    render: {},
    on: {
      default () {
        return {}
      }
    },
    mutateData: {
      type: Boolean,
      default: true
    },
    editSync: {
      type: Boolean,
      default: false
    },
    formatLabel: {
      type: Function
    },
    labelClass: {
      type: Function
    }
  },
  watch: {
    filterShow () {
      this._height = this.myHeight()
    },
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    data: {
      handler () {
        this.setTreeData()
      },
      immediate: true
    }
  },
  methods: {
    handlerNodeClick (data, node) {
      this.$emit('node-select', data)
      this.$emit('node-click', node, data, this.$refs.tree.store)
    },
    // 激活节点
    setActiveNode (id) {
      const node = this.$refs.tree.getNode(id)
      if (node) {
        this.$refs.tree.setCurrentKey(id)
        this.$emit('node-click', node, node.data, this.$refs.tree.store)
      } else {
        this.$refs.tree.setCurrentKey(null)
      }
    },

    // 用于判断当前是否有节点处于编辑或新增状态
    hasInput () {
      if (this.filterShow === 'add') {
        return true
      }
      const input = this.$refs.tree.$el.querySelector('input')
      // input && input.focus()
      return !!input
    },
    focus (isTree) {
      this.removeError(isTree)
    },
    blur (isTree) {
      if (isTree) {
        this.addcText = this.addcText.trim()
      } else {
        this.addText = this.addText.trim()
      }
      this.onlyBlur = true
      setTimeout(() => {
        if (this.onlyBlur) {
          this.setError(undefined, isTree)
        }
      }, 150)
    },
    isError (err, isTree) {
      if (err instanceof Error) {
        this.setError(err.message, isTree)
        return true
      } else {
        return false
      }
    },
    setError (msg, isTree) {
      let input, old
      if (this.$el) {
        if (isTree) {
          input = this.$el.querySelector('.tree .el-input')
        } else {
          input = this.$el.querySelector('.el-input')
        }
      }
      if (input) {
        input.classList.add('error')
        old = input.querySelector('span')
      }

      const span = old || document.createElement('span')
      span.innerHTML = msg || '请先保存!'
      if (!old) {
        input && input.appendChild(span)
      }
    },
    removeError (isTree) {
      let input, span
      if (this.$el) {
        if (isTree) {
          input = this.$el.querySelector('.tree .el-input')
        } else {
          input = this.$el.querySelector('.el-input')
        }
      }
      if (input) {
        input.classList.remove('error')
      }
      if (this.$el) {
        span = this.$el.querySelector('.el-input span')
      }
      if (span) {
        input.removeChild(span)
      }
    },
    clearR () {
      this.filterText = ''
      this.addText = ''
    },
    cancleR () {
      this.onlyBlur = false
      this.clearR()
      this.filterShow = false
    },
    // 添加根节点
    addR () {
      this.onlyBlur = false
      if (this.addText && !this.hasOpt) {
        this.$emit(
          'node-add',
          { label: this.addText, parentId: this.noParent },
          d => {
            if (!this.isError(d)) {
              this.hasOpt = false
              this.clearR()
              this.filterShow = false
              d.children = []
              if (this.rootAddSort === 'down') {
                this.$refs.tree.root.data.push(d)
              } else {
                this.$refs.tree.root.data.unshift(d)
              }
              this.$refs.tree.root.data = Object.assign(
                {},
                this.$refs.tree.root.data
              )
            }
          }
        )
      } else {
        this.setError('不能为空!')
      }
    },
    handleCustomAdd (parentNode) {
      this.$emit('node-add', parentNode, (data) => {
        this.$refs.tree.append(data, parentNode)
        parentNode && parentNode.expand()
      })
    },
    inputToggle (v) {
      // 自定义添加操作
      if (this.customAdd && v === 'add') {
        return this.handleCustomAdd()
      }
      if (
        (this.hasOpt && v === 'add') ||
          (this.filterShow === 'add' && v === 'search')
      ) {
        return false
      }
      this.clearR()
      if (this.filterShow === v) {
        this.filterShow = false
      } else {
        setTimeout(() => {
          this.$el.querySelector('.opt input').focus()
        })
        this.filterShow = v
      }
    },
    handleFilter (command) {
      if (this.hasInput()) {
        return
      }
      this.activeCommand = command
      const value = command === '$all'
        ? ''
        : {
            type: 'filter',
            command
          }
      this.$refs.tree.filter(value)
    },
    filterNode (value, data, node) {
      if (typeof value === 'object') {
        return this.options.filter.handler(value.command, data, node)
      }
      if (!value) return true
      return data[this.props.label].indexOf(value) !== -1
    },
    $clickNode (id) {
      this.$el && this.$el.querySelector(`.tree-item[node_id='${id}']`).click()
    },
    nodeClick (data, node, item) {
      this.$emit('node-click', data, node, item)
    },
    renderContent (h, { node, data, store }) {
      const that = this
      const options = node.data.options || that.options
      if (that.render) {
        return (
            <span node_id={node.id} class='tree-item'>
              {that.render(h, { node, data, store })}
            </span>
        )
      }
      const hasadd = this.canAdd(node)
      const other = options.other && options.other.length
      // 用于控制按钮区域的宽度
      let buttonsCount = 0
      if (hasadd) buttonsCount++
      if (other) buttonsCount++
      // 添加子节点
      const addOk = () => {
        that.onlyBlur = false
        if (that.addcText) {
          that.$emit(
            'node-add',
            {
              label: that.addcText,
              parentId: node.parent.key,
              node: node
            },
            d => {
              if (!that.isError(d, true)) {
                that.hasOpt = false
                d.children = []
                if (that.addSort === 'down') {
                  that.$refs.tree.append(d, node.parent)
                  that.deleteNode(node, data)
                } else {
                  Object.assign(node.data, d)
                }
                that.addcText = ''
              }
            }
          )
        } else {
          that.setError('不能为空!', true)
        }
      }
      if ((data[that.props.nodeKey] + '').slice(0, 6) === 'addKey') {
        // 添加状态
        return (
            <div class='out-add'>
              <el-input
                placeholder={that.placeholder('add', node)}
                value={that.addcText}
                size='mini'
                maxlength={that.maxlength(node)}
                on-input={v => {
                  that.addcText = v
                }}
                on-focus={() => {
                  that.focus(true)
                }}
                on-blur={() => {
                  that.blur(true)
                }}
                nativeOn-keypress={arg => arg.keyCode === 13 && addOk()}
              />
              <span
                class='el-icon-check icon'
                title={that.addTitle(node, 'check') || '确定添加'}
                on-click={addOk}
              />
              <span
                class='el-icon-close icon'
                title='取消'
                on-click={() => {
                  that.hasOpt = false
                  that.addcText = ''
                  that.deleteNode(node, data)
                }}
              />
            </div>
        )
      } else if (node.isEdit) {
        // 修改状态
        const editOk = () => {
          that.onlyBlur = false
          if (that.oldText === that.addcText) {
            that.hasOpt = false
            node.isEdit = false
            that.addcText = ''
            that.$emit('node-edit', {
              nochange: true
            })
            // that.setError('未发生改变!', true)
          } else if (that.addcText) {
            that.$emit(
              'node-edit',
              {
                data: data,
                parentId: node.parent.key,
                label: that.addcText
              },
              d => {
                if (!that.isError(d, true)) {
                  that.hasOpt = false
                  node.isEdit = false
                  node.data[that.props.label] = that.addcText
                  that.addcText = ''
                }
              }
            )
          } else {
            that.setError('不能为空!', true)
          }
        }
        return (
            <div class='out-add'>
              <el-input
                placeholder={that.placeholder('edit', node)}
                size='mini'
                maxlength={that.maxlength(node)}
                value={that.addcText}
                on-input={v => {
                  that.addcText = v
                }}
                on-focus={() => {
                  that.focus(true)
                }}
                on-blur={() => {
                  that.blur(true)
                }}
                nativeOn-keypress={arg => arg.keyCode === 13 && editOk()}
              />
              <span class='el-icon-check icon' title='修改' on-click={editOk} />
              <span
                class='el-icon-close icon'
                title='取消'
                on-click={() => {
                  that.addcText = ''
                  that.hasOpt = false
                  node.isEdit = false
                }}
              />
            </div>
        )
      } else {
        // 正常状态
        const disabled = that.disabled(node)
        const addTitle = that.addTitle(node)
        const mostTitle = that.mostTitle(node)
        const handleEdit = () => {
          if (this.hasInput()) return
          that.addcText = data[that.props.label]
          that.oldText = that.addcText
          that.hasOpt = true
          node.isEdit = true
          if (!that.editSync) {
            node.data = Object.assign({}, node.data)
          }
          setTimeout(() => {
            this.$el.querySelector('.tree input').focus()
          }, 100)
        }
        const icons = []
        if (!options.customIcon) {
          // 添加按钮
          const addIcon = <span
              title={disabled || addTitle || '添加子节点'}
              on-click={e => {
                e.stopPropagation()
                if (disabled) {
                  return false
                }
                const cb = () => {
                  if (!that.hasOpt && that.filterShow !== 'add') {
                    setTimeout(() => {
                      const input = that.$el.querySelector('.tree input')
                      input && input.focus()
                    }, 100)
                    const d = {
                      [that.props.nodeKey]: 'addKey' + Date.now(),
                      [that.props.label]: '',
                      children: []
                    }
                    if (node.childNodes && node.childNodes.length) {
                      that.$refs.tree.insertBefore(d, node.childNodes[0])
                    } else {
                      that.$refs.tree.append(d, node)
                    }

                    // node.data.children.unshift({
                    //   [that.props.nodeKey]: 'addKey' + Date.now(),
                    //   [that.props.label]: '',
                    //   children: []
                    // })
                    // node.data = Object.assign({}, node.data)
                    node.expanded = true
                    that.hasOpt = true
                  }
                }
                if (that.customAdd) {
                  that.handleCustomAdd(node)
                } else if (that.handlerAdd) {
                  that.handlerAdd({ node, data, store }, cb)
                } else {
                  cb()
                }
                e.stopImmediatePropagation()
              }}
              class={{
                'el-icon-plus icon': true,
                none: !hasadd,
                icondisabled: disabled
              }}
            />
          icons.push(addIcon)
        } else {
          // 自定义按钮
          const iconOptions = options.customIcon
          const getValue = (prop, defaultValue) => {
            const value = typeof iconOptions[prop] === 'function' ? iconOptions[prop](node) : iconOptions[prop]
            return value || defaultValue
          }
          if (!getValue('hide')) {
            const icon = h('span', {
              domProps: {
                title: getValue('title', '修改')
              },
              class: {
                [getValue('icon', 'el-icon-edit')]: true,
                icon: true,
                icondisabled: getValue('disabled')
              },
              on: {
                click (e) {
                  if (getValue('disabled')) return
                  const type = getValue('type')
                  if (type === 'edit') {
                    handleEdit()
                  } else {
                    this.$emit('custom-icon-click', node)
                  }
                }
              }
            })
            icons.push(icon)
          }
        }
        const label = this.formatLabel ? this.formatLabel(node) : node.data[that.props.label]
        const labelClass = this.labelClass && this.labelClass(node)
        return (
            <span class={['tree-item']} node_id={node.id}>
              <span
                title={typeof label === 'string' ? label : '' }
                class={['label', `f${buttonsCount}`, labelClass]}
                on-click={() => {
                  that.$emit('node-click', node, data, store)
                }}
              >
                {label}
              </span>
              <div class='icons'>
                { icons }
                <el-dropdown
                  title={mostTitle}
                  trigger='click'
                  on-visible-change={visiable => {
                    if (visiable) {
                      that.activeNode.classList.add('active')
                    } else {
                      that.activeNode.classList.remove('active')
                    }
                  }
                  }
                  on-command={e => {
                    if (e === 'edit') {
                      handleEdit()
                    } else if (e === 'remove') {
                      that.$emit('node-remove', data, () => {
                        that.deleteNode(node, data)
                      })
                    } else {
                      that.$emit('node-most-click', e, data, node, store)
                    }
                  }}
                >
                  <span
                    style={{
                      'pointer-events':
                        that.hasOpt || that.filterShow === 'add' ? 'none' : ''
                    }}
                    on-click={e => {
                      that.activeNode = e.target.parentNode.parentNode.parentNode
                      e.stopPropagation()
                    }}
                    class={{ 'el-icon-more icon': true, none: !other }}
                  />
                  <el-dropdown-menu slot='dropdown'>
                    {(options.other || []).map(it => {
                      const bind = it.bind ? Object.assign({}, it.bind) : { props: it.props }
                      if (bind.props && typeof bind.props.disabled === 'function') {
                        const index = node.parent.childNodes.indexOf(node)
                        const disabled = bind.props.disabled.call(null, node, data, index)
                        bind.props = Object.assign({}, bind.props, { disabled })
                      }
                      return h('el-dropdown-item', bind, it.label)
                    })}
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </span>
        )
      }
    },
    canAdd (node) {
      const options = (node && node.data.options) || this.options
      return typeof options.add === 'function' ? options.add.call(null, node) : options.add
    },
    myHeight () {
      return window.innerHeight - this.autoHeight
    },
    deleteNode (node, data) {
      data && this.$refs.tree.remove(data)
      node && this.$refs.tree.remove(node)
    },
    onresize () {
      this._height = this.myHeight()
      this.$forceUpdate()
    },
    listResize () {
      window.addEventListener('resize', this.onresize)
    },
    closeResize () {
      window.removeEventListener('resize', this.onresize)
    },
    add () {
      this.$emit('add')
    },
    setTreeData () {
      const data = this.data
      const tree = []
      const map = {}
      for (let i = 0; i < data.length; i++) {
        const item = this.mutateData ? data[i] : Object.assign({}, data[i])
        const key = item[this.props.nodeKey]
        const pkey = item[this.props.parentKey]
        const obj = {}
        obj[this.defaultProps.children] = []
        map[key] = Object.assign(item, obj, map[key])
        if (pkey === this.noParent) {
          tree.push(item)
        } else {
          item.parent = map[pkey]
          if (map[pkey]) {
            map[pkey][this.defaultProps.children].push(item)
          } else {
            const obj = {}
            obj[this.defaultProps.children] = [item]
            map[pkey] = obj
          }
        }
      }
      this.$set(this, 'treeData', tree)
    },
    /** 获取整棵树的数据（非树形结构） */
    getTreeData (ignoreKeys = ['parent', this.defaultProps.children]) {
      const data = []
      const parseChildren = children => {
        if (!children || !children.length) return
        children.forEach(child => {
          const newChild = {}
          each(child, (val, key) => {
            if (!ignoreKeys.includes(key)) {
              newChild[key] = val
            }
          })
          data.push(newChild)
          parseChildren(child[this.defaultProps.children])
        })
      }
      parseChildren(this.treeData)
      return data
    },
    /** 根据数据或 id 获取节点 */
    getNode (data) {
      const tree = this.$refs.tree
      const node = tree.store.getNode(data)
      if (node) {
        return node
      }
      const nodesMap = tree.store
      for (const key in nodesMap) {
        const node = nodesMap[key]
        if (node) {
          if (typeof data === 'object' && data === node.data) {
            return node
          } else if (typeof data !== 'object' && data === node.data[this.props.nodeKey]) {
            return node
          }
        }
      }
      return null
    },
    /** 插入新节点 */
    insertNode (parent, child, index) {
      parent = this.getNode(parent)
      if (!parent) {
        if (process.env.NODE_ENV === 'development') {
          console.error('对应的父节点不存在！')
        }
      } else {
        parent.insertChild(child, index)
      }
    }
  },
  computed: {
    filterOptions () {
      const options = this.options && this.options.filter && this.options.filter.options
      if (!options) return null
      return [{ label: '全部', command: '$all' }].concat(options)
    },
    delHeight () {
      const opt = this.$el && this.$el.querySelector('.hcd-tree>.opt')
      return this.filterShow ? (opt ? opt.clientHeight : 48) : 0
    },
    defaultProps () {
      return {
        label: this.props.label,
        children: this.props.children || 'children',
        isLeaf: this.props.isLeaf || 'leaf'
      }
    }
  },
  created () {
    if (this.autoHeight) {
      this._height = this.myHeight()
      this.listResize()
    }
  }
}
</script>
<style lang="less">
  .hcd-tree {
    width: 240px;
    border: 1px solid #e4e8f1;
    font-size: 14px;
    &.reSizeable {
      overflow-x: scroll;
      resize: horizontal;
    }
    .is-current .el-tree-node__content .icons .icon {
      background-color: transparent;
    }
    .el-tree-node__content {
      .out-add .el-input {
        width: 100%;
      }
      .icon {
        display: none;
        background-color: #fff;
      }
      &:hover ,.active{
        .out-add .el-input {
          width: calc(~"100% - 52px");
        }
        .icon {
          display: inline;
          background-color:transparent;
          &.none {
            display: none;
          }
        }
      }
    }
    .error {
      input {
        border: 1px solid #f56c6c;
        position: relative;
      }
      span {
        position: absolute;
        bottom: -16px;
        left: 4px;
        color: #f56c6c;
        z-index: 500;
      }
    }
    .none {
      display: none;
    }
    .opt {
      > .el-input {
        margin: 10px;
        box-sizing: border-box;
        width: 220px;
        input {
          border-radius: 28px;
        }
      }
      .out-add {
        font-size: 0px;
        padding: 10px;
        padding-right: 0;
        .el-icon-close {
          margin-right: 0;
        }
        .icon {
          font-size: 14px;
          color: #409eff;
          text-align: center;
        }
        .el-input {
          width: calc(~"100% - 56px");
        }
      }
    }
    .out-add {
      font-size: 0px;
      .el-input {
        width: calc(~"100% - 50px");
      }
      .icon {
        padding: 5px;
        color: #409eff;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
        vertical-align: baseline;
      }
      .el-icon-check {
        margin-left: 4px;
      }
    }
    .title {
      background-color: #fff;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      padding-right: 4px;
      font-size: 14px;
      color: #475669;
      font-weight: 700;
      border-bottom: 1px solid #e4e8f1;
      .icon {
        float: right;
        padding: 5px;
        cursor: pointer;
      }
      .el-icon-plus {
        margin-top: 10px;
      }
      .el-icon-search {
        margin-top: 10px;
      }
      .el-dropdown {
        float: right;
        height: 36px;
        .filter-icon {
          padding: 5px;
          cursor: pointer;
        }
      }
      .filtered-dropdown {
        .filter-icon {
          color: #409eff;
        }
      }
    }
    .el-tree {
      border: none;
      .delete {
        float: right;
        line-height: 36px;
        margin-right: 5px;
      }
    }

    .tree {
      overflow: auto;
      .out-add {
        position: absolute;
        left: 0;
        padding: 4px;
      }
      .tree-item {
        width: 100%;
        overflow: hidden;
        position: relative;
        .icons {
          position: absolute;
          top: 0;
          right: 10px;
          line-height: 23px;
        }
        .icon {
          vertical-align: text-top;
          margin-top: -5px;
          padding: 5px;
        }
      }
      .label {
        display: inline-block;
        // text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: text-bottom;
        overflow: hidden;
      }
      .label.f1 {
        width: 100%;
      }
      .label.f1 {
        width: calc(~"100% - 29px");
      }
      .label.f2 {
        width: calc(~"100% - 52px");
      }
    }
    .el-tree-node__content {
      height: 36px;
    }
    .el-tree-node.is-current > .el-tree-node__content {
      // background-color: #e4e8f1;
    }
    .icondisabled {
      color: #ccc;
    }
  }
  .hcd-tree__active-item,
  .el-dropdown-menu__item.hcd-tree__active-item:focus,
  .el-dropdown-menu__item.hcd-tree__active-item:not(.is-disabled):hover {
    background-color: #409EFF;
    color: #fff;
  }
  </style>
