<template>
  <div class="hcd-page">
    <div class="hcd-left" v-if="$slots.left">
      <slot name="left"></slot>
    </div>
    <div class="hcd-right">
      <div class="hcd-search" v-if="$slots.header">
        <slot name="header"></slot>
      </div>
      <div class="hcd-table">
          <el-table default-expand-all  row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" class="hcd-table-list" @selection-change="handleSelectChange" :data="list" height="100%">
              <el-table-column v-if="hasSelect" type="selection" width="55"></el-table-column>
              <template v-for="item in cols" >
                  <el-table-column v-if="!colsHide[item.name]" :key="item.name" :fixed="item.fixed ? 'right' : null" :label="item.label" :width="item.width" >
                      <template slot-scope="scope" >
                          <table-column-content :row="scope.row" :item="item">
                          </table-column-content>
                      </template>
                  </el-table-column>
              </template>
          </el-table>
          <div class="col-setting" v-if="colDisplayControl">
            <el-popover
            popper-class="cols-setting-popper"
            placement="bottom"
            width="200"
            trigger="click">
              <i slot="reference" class="el-icon-s-tools"></i>
              <ul class="cols-setting">
                <li v-for="item in opCols" :key="item.name" >
                  <el-checkbox :value="getColSelected(item.name)"  @input="setColSelected(item.name)"></el-checkbox>
                  <span style="margin-left: 8px">{{ item.label }}</span>
                </li>
              </ul>
            </el-popover>
          </div>

      </div>
      <div class="hcd-pagination" v-if="!isTree">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="size"
              :total="total">
          </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from 'vue'
const TableColumnContent = ({ props }) => {
  const { row, item } = props
  if (item.render) {
    return (<span>{ item.render(h, row)}</span>)
  } else {
    return <span>
        { row[item.name] || '-'}
    </span>
  }
}

export default {
  components: {
    TableColumnContent
  },
  props: {
    isTree: {
      type: Boolean,
      default () {
        return false
      }
    },
    search: {
      type: Function,
      default () {
        return () => {}
      }
    },
    cols: {
      type: Array,
      default () {
        return [] // { label, name, render, isSelect }
      }
    },
    colDisplayControl: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasSelect: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      list: [],
      page: 1,
      size: 20,
      total: 0,
      colsHide: {}
    }
  },
  computed: {
    opCols () {
      return this.cols.filter(row => !row.exclude)
    }
  },
  watch: {
    cols: {
      handler (val) {
        if (val && val.length > 0) {
          const colsHide = {}
          val.forEach(row => {
            colsHide[row.name] = this.colDisplayControl ? !!row.hide : false
          })
          this.colsHide = colsHide
        } else {
          this.colsHide = {}
        }
      },
      immediate: true,
      deep: true
    }

  },
  mounted () {
    this.handleEmitSearch()
  },
  methods: {
    handleSizeChange (val) {
      this.size = val
      this.handleEmitSearch()
    },
    handleCurrentChange (val) {
      this.page = val
      this.handleEmitSearch()
    },
    async handleEmitSearch () {
      if (this.search) {
        const { list, total } = await this.search(this.page, this.size)
        this.list = list || []
        this.total = total || 0
      }
    },
    async reload () {
      await this.handleEmitSearch()
    },
    getColSelected (name) {
      return !this.colsHide[name]
    },
    setColSelected (name) {
      this.$set(this.colsHide, name, !this.colsHide[name])
      this.$emit('col-display-change', {
        name,
        isHide: this.colsHide[name]
      })
    },
    handleSelectChange (selection) {
      this.$emit('selectChange', selection)
    }
  }
}
</script>

<style lang="less" scoped>
.hcd-page  {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 100%;
  box-sizing: border-box;
  .hcd-right {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 100%;
    width: 0;
  }
  .hcd-left {
    flex: 0 0 200px;
    height: 100%;
    margin-right: 20px;
  }
  .hcd-search {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }
  .hcd-table {
    flex: 1 1 auto;
    position: relative;
    .hcd-table-list {
      border: 1px solid #EBEEF5;
      /deep/ thead {
        color: #333;
        th {
          background-color: #f5f6fa;
        }
      }
    }
    .col-setting {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      z-index: 3;
      color: #333;

      justify-content: center;
      i {
        cursor: pointer;
      }
    }
  }
  .hcd-pagination {
    flex:  0 0 40px;
    text-align: right;
    margin-top: 8px;
    /deep/ .el-pagination{
        margin-top: 10px;
    }
  }
}

</style>

<style lang="less">
.cols-setting-popper {
  max-height: 500px;
  width: 200px;
  overflow-y: auto;
}
</style>
