<template>
  <div class="hcd-half-screen-dialog">
    <div :class="{'hcd-dialog-mask': true, active: classActive}" v-show="modal" @click="clickClose"></div>
    <div :class="{
      'hcd-half-full-screen-box': true,
      'active': classActive
    }"
    :style="{
      width:  width + 'px'
    }"
    >
      <div class="dialog-header">
        <span class="dialog-back" @click="clickClose">
          <i class="el-icon-close"></i>
          关闭
        </span>
        <span class="dialog-title">{{title}}</span>
        <el-tooltip effect="dark" :content="iconTip" placement="bottom" v-if="iconClass">
          <i :class="iconClass"></i>
        </el-tooltip>
        <slot name="head"></slot>
      </div>
      <div class="dialog-body" :class="{
        hasfooter: $slots.footer ? true : false
      }">
        <slot></slot>
      </div>
      <div class="dialog-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hcd-half-screen-dialog',
  props: {
    width: {
      type: [String, Number],
      default: 799
    },
    title: {
      type: String,
      required: true
    },
    beforeClose: {
      type: Function
    },
    iconClass: {
      type: String
    },
    iconTip: {
      type: String
    },
    modal: {
      type: Boolean,
      default: true
    },
    lockScoll: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      classActive: false
    }
  },
  created () {
    if (this.lockScoll) {
      document.body.style.overflow = 'hidden'
    }
    setTimeout(() => {
      this.classActive = true
    }, 50)
  },
  mounted () {
  },
  methods: {
    clickClose () {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.close)
      } else {
        this.close()
      }
    },
    close (cancel) {
      if (cancel !== false) {
        this.classActive = false
        setTimeout(() => {
          document.body.style.overflow = ''
          this.$emit('input', false)
          this.$emit('close')
        }, 350)
      }
    }
  },
  destroyed () {
  }
}
</script>
<style lang="less">
  .hcd-half-screen-dialog{
    z-index: 999;
    position: relative;
    .hcd-dialog-mask{
      position: fixed;
      width: 100%;
      height: 100%;
      left:0;
      top:0;
      background: #000;
      opacity: 0;
      transition: all .35s ease-out;
      &.active{
        opacity: .5;
      }
    }
    .hcd-half-full-screen-box{
      position: fixed;
      right: 0;
      width: 799px;
      border-left: 1px solid #dcdfe6;
      top: 0;
      bottom: 0;
      background: #fff;
      transition: all .35s ease-out;
      transform: translate(100%, 0);
      &.active{
        transform: translate(0, 0);
      }
      .dialog-header{
        line-height: 49px;
        height:49px;
        border-bottom: 1px solid #dcdfe6;
        font-size: 14px;
        padding:0 20px 0 82px;
        color: #606266;
        background: #fff;
        .dialog-back{
          color: #909399;
          cursor: pointer;
          font-size: 16px;
          line-height: inherit;
          margin-left: -82px;
          float: left;
          padding-left: 20px;
          &:hover{
            color: #409EFF;
          }
        }
        .dialog-title{
          font-size: 16px;
          color: #303133;
          padding-left: 10px;
          line-height: 1;
          border-left: 1px solid #909399;
        }
        .el-tooltip{
          font-size: 16px;
          margin-right: 20px;
        }
      }
      .dialog-body{
        position: absolute;
        top: 50px;
        bottom: 0;
        width: 100%;
        overflow: auto;
        &.hasfooter{
          bottom: 63px;
        }
      }
      .dialog-footer{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #fff;
        border-top: 1px solid #dcdfe6;
      }
    }
  }
</style>
