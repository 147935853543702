<template>
    <div class="hcd-edit-dialog" v-if="visible">
        <div class="header" >
            <i class="el-icon-arrow-left point" @click="goBack"></i>
            <span class="title point"  @click="goBack">返回</span>
            <span class="split"></span>
            <span class="title">{{  title }}</span>
        </div>
        <div class="content">
            <div class="">
                <slot></slot>
            </div>
        </div>
        <div class="floor" v-if="!view">
            <el-button type="primary" @click="$emit('submit')" >保存</el-button>
            <el-button @click="$emit('update:visible', false)">取消</el-button>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    visible: {
      default: false
    },
    title: {
      default: ''
    },
    view: {
      default: false
    }
  },
  methods: {
    goBack () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>
.hcd-edit-dialog {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 5;
    .header {
        flex: 0 auto;
        padding: 20px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        background-color: #fff;
        font-weight: 500;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 1px 0px 0px #e6e7ef;
        .point {
            line-height: 22px;
            cursor: pointer;
                      margin-right: 5px;
        }
        .split {
            width: 0px;
            height: 23px;
            border-right: 1px solid #e6e6e6;
            margin-right: 10px;
            margin-left: 5px;
        }
        .title {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: rgba(42,42,44,0.98);
            line-height: 20px;
        }
    }
    .content {
        padding: 20px;
        flex: 1 auto;
        overflow-y: auto;
    }
    .floor {
        flex: 0 auto;
        padding: 0px 30px;
        line-height: 72px;
        box-shadow: 0px -10px 10px -1px rgba(86,86,86,0.07);
    }

}
</style>
