<template>
  <div>
    <el-cascader
      filterable
      :value="displayValue"
      collapse-tags
      @input="handleChangeValue"
      :props="{ value: 'label', label: 'label', multiple: multiple, emitPath: emitPath, checkStrictly: false }"
      :options="options">
  </el-cascader>
 </div>
</template>

<script>
import { AREA_TREE, AREA_CITY_TREE } from '@/web/utils/constant.js'

export default {
  props: {
    value: {
      type: String,
      default () {
        return null
      }
    },
    onlyCity: {
      type: Boolean,
      default () {
        return false
      }
    },
    multiple: {
      type: Boolean,
      default () {
        return false
      }
    },
    emitPath: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  computed: {
    options () {
      return this.onlyCity ? AREA_CITY_TREE : AREA_TREE
    },
    displayValue () {
      if (this.multiple) {
        return this.value
      } else {
        return (this.value || '').split(',')
      }
    }
  },
  methods: {
    handleChangeValue (val) {
      if (this.multiple) {
        this.$emit('input', val)
      } else {
        this.$emit('input', (val || []).join(','))
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
