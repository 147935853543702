<template>
    <el-input class="hcd-amount-input" :disabled="disabled" @keypress.native="handleKeypress" @blur="handleChangeFocus(false)" @focus="handleChangeFocus(true)" v-model="amount"  style="width: 300px;">
        <span style="margin-right: 10px" slot="suffix">元</span>
    </el-input>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    value: {
      default () {
        return null
      }
    }

  },
  computed: {
    amount: {
      get () {
        if (this.value) {
          if (this.isFocus) {
            return this.value
          } else {
            return parseInt(this.value).toLocaleString()
          }
        } else {
          return null
        }
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  methods: {
    handleChangeFocus (val) {
      this.isFocus = val
    },
    handleKeypress (event) {
      if (!/\d/.test(event.key)) {
        event.stopPropagation()
        event.preventDefault()
      }
    }
  }
}
</script>

<style lang="less">
.hcd-amount-input {
    display: inline-flex;
    align-items: center;
}
</style>
