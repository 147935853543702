import axios from 'axios'

export function debounce (func, wait, immediate = false) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = undefined
      if (!immediate) {
        func.apply(context, args)
      }
    }
    if (immediate && !timeout) {
      func.apply(context, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export function textCopy (t) {
  return new Promise((resolve) => {
    if (!navigator.clipboard) {
      const ele = document.createElement('textarea')
      ele.value = t
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      if (document.execCommand('copy')) {
        console.log('复制成功！')
        resolve(true)
      } else {
        console.log('复制失败！')
        resolve(false)
      }
    } else {
      navigator.clipboard.writeText(t).then(function () {
        console.log('复制成功！')
        resolve(true)
      }).catch(function () {
        console.log('复制失败！')
        resolve(false)
      })
    }
  })
}

export function listtoTree (arr, id = 'id', pid = 'parentid', child = 'children', root = 0) {
  const tree = []
  const map = {}
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const key = item[id]
    const pkey = item[pid]
    const obj = {}
    obj[child] = []
    Object.assign(item, obj, map[key])
    if (map[key]) {
      map[key][child].forEach(function (child) {
        child[pid] = item
      })
    }
    map[key] = item
    if (pkey === root) {
      tree.push(item)
    } else {
      // item[pid] = map[pkey]
      if (map[pkey]) {
        map[pkey][child].push(item)
      } else {
        const obj = {}
        obj[child] = [item]
        map[pkey] = obj
      }
    }
  }
  for (const key in map) {
    if (!map[key].children.length) {
      delete map[key].children
    }
  }
  return tree
}

export async function uploadFile (file) {
  const formData = new FormData()
  formData.append('file', file)
  const res = await axios.post('/file/uploadFile', formData)
  return res.data.result
}

export function dateFormat (date, fmt) { // author: meizz
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}

export async function downloadFile (url, filename = null) {
  const res = await axios.get(url, { responseType: 'blob' })
  const miniType = res.headers['content-type']
  const blob = new Blob([res.data], { type: miniType })
  if (!filename) {
    return blob
  } else {
    const localUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = localUrl
    a.click()
    window.URL.revokeObjectURL(localUrl)
  }
}

export function downloadFromBlob (blob, filename) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = filename
  a.href = url
  a.click()
}
export function isObject (obj) {
  return obj !== null && typeof obj === 'object'
}

export function isArray (obj) {
  if (!Array.isArray) {
    Array.isArray = function (arg) {
      return Object.prototype.toString.call(arg) === '[object Array]'
    }
  }
  return Array.isArray(obj)
}

export function each (obj, iterator) {
  if (isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      iterator.call(obj[i], obj[i], i)
    }
  } else if (isObject(obj)) {
    for (const key in obj) {
      if ({}.hasOwnProperty.call(obj, key)) {
        iterator.call(obj[key], obj[key], key)
      }
    }
  }
}
