<template>
    <div class="hcd-frame">
        <div class="menus">
            <div class="title">
                青蚁客户运营管理
            </div>
            <ul>
                <li class="menu" :class="{active : currentMenuPath === item.aPath}"  v-for="(item,index) in menus" :key="index" @click="goto(item)">
                   <i class="el-icon-s-home"></i><span style="margin-left: 8px;">{{  item.meta.title }}</span>
                </li>
            </ul>
        </div>
        <div class="container">
          <div class="header">
            <el-popover
              ref="popover"
              popper-class="user-popover"
              placement="bottom"
              width="200"
              trigger="click"
            >
              <div class="user" slot="reference">
                <img :src="defaultAvater"/>
                <span class="name">{{ username }}</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <ul class="user-menus" @click="handlePopover">
                <li class="user-menu" @click="openPasswordDialog">修改密码</li>
                <li class="user-menu" @click="handleLogout">退出登录</li>
              </ul>

            </el-popover>

          </div>
          <div class="content">
            <router-view />
          </div>
        </div>
        <el-dialog append-to-body width="500px" title="修改密码" :visible.sync="passwordDialogVisible">
           <el-form ref="form" label-width="100px" :model="formData">
              <el-form-item label="绑定手机号" >
                <el-input disabled v-model="formData.phone" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" :rules="[{required: true, message: '请输入短信验证码'}]">
                <el-input v-model="formData.code"  style="width: 180px">
                </el-input>
                <el-button :disabled="smsBtnDisabled" style="margin-left: 20px;width: 100px" @click="sendSmsCode">
                   {{ smsText }}
                </el-button>
              </el-form-item>
              <el-form-item label="新密码" prop="password" :rules="[{required: true, message: '请输入新密码'}]">
                <el-input style="width: 300px" auto-complete="off" type="password" v-model="formData.password"></el-input>
              </el-form-item>
              <el-form-item label="确认新密码" prop="repassword" :rules="[{required: true, message: '请输入新密码'}, {validator: validatePass}]">
                <el-input style="width: 300px" auto-complete="off" type="password" v-model="formData.repassword"></el-input>
              </el-form-item>
           </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="passwordDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleChangePassword">修改密码</el-button>
          </span>
        </el-dialog>
        <el-dialog append-to-body width="500px" :title="passwordDialogTitle" :visible.sync="passwordDialogVisible">
           <el-form ref="form" label-width="100px" :model="formData">
              <el-form-item v-if="passwordDialogTip" label-width="0px">
                 <span style="margin-left: 10px">{{ passwordDialogTip }}</span>
              </el-form-item>
              <el-form-item label="绑定手机号" >
                <el-input disabled v-model="formData.phone" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" :rules="[{required: true, message: '请输入短信验证码'}]">
                <el-input v-model="formData.code"  style="width: 180px">
                </el-input>
                <el-button :disabled="smsBtnDisabled" style="margin-left: 20px;width: 100px" @click="sendSmsCode">
                   {{ smsText }}
                </el-button>
              </el-form-item>
              <el-form-item label="新密码" prop="password" :rules="[{required: true, message: '请输入新密码'}]">
                <el-input style="width: 300px" auto-complete="off" type="password" v-model="formData.password"></el-input>
              </el-form-item>
              <el-form-item label="确认新密码" prop="repassword" :rules="[{required: true, message: '请输入新密码'}, {validator: validatePass}]">
                <el-input style="width: 300px" auto-complete="off" type="password" v-model="formData.repassword"></el-input>
              </el-form-item>
           </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="passwordDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleChangePassword">修改密码</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import defaultAvater from '@/web/assets/images/avater.png'
import { routerList } from '@/web/router/router.main.js'
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value !== this.formData.password) {
        callback(new Error('二次输入的密码不相同'))
      } else {
        callback()
      }
    }
    return {
      validatePass,
      changedPassWord: window.localStorage.getItem('changedPassWord') || 0,
      username: window.localStorage.getItem('name'),
      defaultAvater,
      menus: [],
      currentMenuPath: window.location.hash.substring(1),
      formData: {
        password: null,
        repassword: null,
        phone: null,
        code: null
      },
      passwordDialogVisible: false,
      smsText: '发送验证码',
      smsBtnDisabled: false,
      passwordDialogTitle: '修改密码',
      passwordDialogTip: '为了保证你的账号安全，请先修改默认登录密码'
    }
  },
  beforeCreate () {
    this.$router.beforeEach((to, form, next) => {
      this.currentMenuPath = to.path
      next()
    })
  },
  mounted () {
    const permission = this.getPermissionList()
    this.menus = routerList.filter(row => {
      return permission.includes(row.meta.code)
    })
    if (this.menus.length > 0 && this.$route.path === '/') {
      this.$router.replace(this.menus[0].path)
    }
    if (!parseInt(this.changedPassWord)) {
      this.openPasswordDialog(true)
    }
  },
  methods: {
    goto (item) {
      this.$router.push({ path: item.aPath })
    },
    handlePopover () {
      this.$refs.popover.doClose()
    },
    async handleLogout () {
      window.localStorage.clear()
      await this.$axios.post('/user/loginOut')
      this.$router.push({ path: '/login' })
    },
    openPasswordDialog (isFirst) {
      if (isFirst === true) {
        this.passwordDialogTitle = '修改密码提醒'
        this.passwordDialogTip = '为了保证你的账号安全，请先修改默认登录密码'
      } else {
        this.passwordDialogTitle = '修改密码'
        this.passwordDialogTip = ''
      }
      this.formData.phone = window.localStorage.getItem('phone')
      this.formData.code = null
      this.formData.password = null
      this.formData.repassword = null
      this.smsText = '发送验证码'
      clearInterval(this.smsTimer)
      this.smsTimer = null
      this.smsBtnDisabled = false
      this.passwordDialogVisible = true
    },
    async handleChangePassword () {
      await this.$refs.form.validate()
      await this.$axios.post('/sms/validCode', { biz: 'modify', phone: this.formData.phone, code: this.formData.code })
      await this.$axios.put('/user/init/password', { password: this.formData.password })
      this.$message.success('修改成功')
      this.passwordDialogVisible = false
    },
    async sendSmsCode () {
      this.$axios.post('/sms/getIdentifyingCode', { biz: 'modify', phone: this.formData.phone })
      this.smsText = 60
      this.smsBtnDisabled = true
      this.smsTimer = setInterval(() => {
        this.smsText--
        if (this.smsText === 0) {
          clearInterval(this.smsTimer)
          this.smsBtnDisabled = false
          this.smsText = '发送验证码'
        }
      }, 1000)
    }
  }

}
</script>

<style scoped lang="less">
.hcd-frame {
    display: flex;
    flex-direction: row;
    background-color: #F9F9F9;
    height: 100%;
  .menus {
    flex: 0 0 200px;
    background-color: #222944;
    color: #fff;
    .title {
      color: #fff;
      font-size: 18px;
      text-align: center;
      height: 50px;
      line-height: 50px;
      background-color: #081240;
      margin-bottom: 10px;
    }
    .menu {
      height: 40px;
      padding: 0 20px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      &+.menu {
        margin-top: 10px;
      }
      &:hover {
        background-color: #4381FF;
      }
      &.active {
        background-color: #4381FF;
      }
    }
  }
  .container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 0;
    .header {
      flex: 0 0 50px;
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px #ebebeb;
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      padding: 0 20px;
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #808080;
        cursor: pointer;
        img {
          height: 32px;
          margin-right: 8px;
        }
        .name {
          margin-right: 8px;
        }
      }
    }
    .content {
      flex: 1 1 auto;
      margin: 20px;
      background-color: #fff;
      position: relative;
    }
  }

}

.sms-code-send {

}

</style>
