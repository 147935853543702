<template>
    <div class="hcd-carno-input">
        <el-select :disabled="disabled" style="width: 60px;" v-model="area">
            <el-option v-for="item,index in CAR_AREA" :key="index" :value="item"></el-option>
        </el-select>
        <el-input :disabled="disabled" style="width: 100px;margin-left: 10px" v-model="no"></el-input>
    </div>
</template>

<script>
import { CAR_AREA } from '@/web/utils/constant.js'
export default {
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    value: {
      type: String,
      default () {
        return null
      }
    }
  },
  computed: {
    area: {
      get () {
        return this.value ? this.value[0] : '湘'
      },
      set (val) {
        this.$emit('input', val + this.no)
      }
    },
    no: {
      get () {
        return this.value ? this.value.substr(1) : ''
      },
      set (val) {
        this.$emit('input', this.area + val)
      }
    }
  },
  data () {
    return {
      CAR_AREA
    }
  }
}
</script>

<style lang="less">
.hcd-carno-input {
    display: inline-flex;
    align-items: center;
}
</style>
