<template>
    <el-popover
    ref="popover"
    popper-class="hcd-more-buttom-popover"
    placement="bottom-start"
    :visible-arrow="false"
    width="100"
    trigger="click">
    <el-button class="more-btn" type="text" slot="reference" >...</el-button>
    <ul class="menus">
      <li class="menu" @click="handleClick(item)" v-for="item,index in menus" :key="index">
        {{ item.label }}
      </li>
    </ul>
  </el-popover>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    handleShowMenu () {
      this.$refs.popover.doShow()
    },
    handleClick (item) {
      if (item.click) {
        item.click()
      }
      this.$refs.popover.doClose()
    }
  }
}
</script>

<style lang="less" scoped>
.more-btn {
  margin-left: 8px;
}
.menus {
  .menu {
    cursor: pointer;
    line-height: 32px;
    padding: 0 12px;
    &:hover {
      background-color: #ececec;
    }
  }
}

</style>
<style lang="less">
.hcd-more-buttom-popover {
  padding: 0px !important;
  margin: 0 !important;
}
</style>
